<script lang="ts">
    import Button from '$lib/components/ui/button/button.svelte';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import * as Dialog from '$lib/components/ui/dialog';
    import { page } from '@inertiajs/svelte';
    import { onMount, onDestroy } from 'svelte';
    import { get } from 'svelte/store';
    import ListView from './MediaVaultListView.svelte';
    import { Circle } from 'svelte-loading-spinners';
    import {
        isGridView,
        folderName,
        isDialogOpen,
        currentFolderId,
        parentFolderId,
        rootItems,
        subItems,
        activeDropdown,
        isDeleteDialogOpen,
        openFolderContextMenu,
        closeDropdown,
        onDragStart,
        onDrop,
        fetchSubFolders,
        openCreateFolderDialog,
        createFolder,
        handleFileUpload,
        openFolder,
        goBack,
        openDeleteDialog,
        confirmDelete,
        openPreview,
        truncateText,
        fetchDataPage,
        isFetching,
        hasMoreItems,
        subIsFetching,
        subHasMoreItems,
        itemToMove,
        isMoveDialogOpen,
        openMoveDialog,
        moveItem,
        selectMoveFolder,
        selectedMoveFolder,
        previewMedia,
        previewMediaType,
        isMediaPreviewOpen,
        isRenameDialogOpen,
        newItemName,
        openRenameDialog,
        renameItem,
        moveDialogFolderId,
        goBackMoveDialog,
        openMoveDialogFolder,
    } from './MediaVaultLogic.svelte';

    $: parentFolderId.set($page || null);

    const fetchData = () => {
        let localCurrentFolderId;
        if (!localCurrentFolderId) {
            const { mediaVaultItems = [] } = $page.props;
            rootItems.set(mediaVaultItems);
        } else {
            fetchSubFolders(localCurrentFolderId);
        }
    };
    onMount(fetchData);
    function handleScroll() {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - 50) {
            if (get(currentFolderId)) {
                if (!get(subIsFetching) && get(subHasMoreItems)) {
                    fetchSubFolders(get(currentFolderId)).finally(() => subIsFetching.set(false));
                }
            } else {
                if (!get(isFetching) && get(hasMoreItems)) {
                    fetchDataPage().finally(() => isFetching.set(false));
                }
            }
        }
    }

    onMount(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onDestroy(() => {
        window.removeEventListener('scroll', handleScroll);
    });
</script>

<div class="min-h-screen px-12 py-6">
    <div class="mb-6 flex items-center justify-between">
        <h1 class="text-2xl font-bold">Media Vault</h1>
    </div>
    <div class="flex items-center justify-between rounded-lg">
        <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <Button class="flex items-center space-x-2 rounded-md px-4 py-2 text-sm font-medium" variant="outline">
                    <span class="flex items-center gap-2"
                        ><ion-icon class="h-[20px] w-[20px]" name="add-outline"></ion-icon> Add New</span
                    >
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content class="mt-2 w-48 rounded-md">
                <DropdownMenu.Group>
                    <DropdownMenu.Item
                        on:click="{() => handleFileUpload('image')}"
                        class="flex cursor-pointer gap-2 px-4 py-2"
                    >
                        <ion-icon class="h-[25px] w-[25px]" name="image-outline"></ion-icon>
                        Upload Image
                    </DropdownMenu.Item>
                    <DropdownMenu.Item
                        on:click="{() => handleFileUpload('video')}"
                        class="flex cursor-pointer gap-2 px-4 py-2 "
                    >
                        <ion-icon class="h-[25px] w-[25px]" name="videocam-outline"></ion-icon>
                        Upload Video
                    </DropdownMenu.Item>

                    <DropdownMenu.Item on:click="{openCreateFolderDialog}" class="flex cursor-pointer gap-2 px-4 py-2">
                        <ion-icon class="h-[25px] w-[25px]" name="folder-outline"></ion-icon>
                        New Folder
                    </DropdownMenu.Item>
                </DropdownMenu.Group>
            </DropdownMenu.Content>
        </DropdownMenu.Root>

        <div class="flex gap-2">
            {#if $currentFolderId}
                <Button variant="outline" class="rounded-md  px-4 py-2" on:click="{goBack}">⬅ Back</Button>
            {/if}
            <div class="flex h-[39px] w-[135px] rounded-md border">
                <button
                    on:click="{() => isGridView.set(true)}"
                    class="h-full w-[50%] {$isGridView ? 'bg-[#D9D9D933]' : ''} rounded-md"
                    aria-pressed="{$isGridView}"
                >
                    <ion-icon class="h-[40px] w-[30%]" name="grid-outline"></ion-icon>
                </button>
                <button
                    on:click="{() => isGridView.set(false)}"
                    class="h-full w-[50%] {!$isGridView ? 'bg-[#D9D9D933]' : ''} rounded-md"
                    aria-pressed="{!$isGridView}"
                >
                    <ion-icon class="h-[35px] w-[40%]" name="list-outline"></ion-icon>
                </button>
            </div>
        </div>
    </div>

    {#if $isGridView}
        {#if !$currentFolderId}
            {#if $rootItems.length === 0}
                <div class="flex h-[50vh] items-center justify-center text-lg text-gray-500">No data available</div>
            {/if}
            <div class="min-h-screen py-6">
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
                    {#each $rootItems as item}
                        {#if $rootItems.length === 0}
                            <div class="flex h-[50vh] items-center justify-center text-lg">No data available</div>
                        {/if}
                        <DropdownMenu.Root
                            open="{$activeDropdown === item.id}"
                            on:openChange="{(e) => (e.detail ? null : closeDropdown())}"
                        >
                            <div
                                role="button"
                                tabindex="0"
                                draggable="true"
                                on:dragstart="{(e) => onDragStart(e, item)}"
                                class="flex flex-col items-center space-y-2 rounded-lg text-center"
                                on:contextmenu="{(e) => openFolderContextMenu(e, item ?? null)}"
                            >
                                <DropdownMenu.Trigger>
                                    <div
                                        role="button"
                                        tabindex="0"
                                        class="flex h-[150px] w-[150px] cursor-pointer items-center justify-center rounded-lg"
                                        on:dblclick="{() => openFolder(item)}"
                                    >
                                        {#if item.media_type === 'image'}
                                            <button
                                                type="button"
                                                class="h-full w-full cursor-pointer rounded-md object-contain"
                                                on:click="{() => openPreview(item.attachment.path, 'image')}"
                                            >
                                                <img
                                                    src="{item.attachment.thumbnail}"
                                                    alt="{item.name}"
                                                    loading="lazy"
                                                    class="h-full w-full rounded-md object-contain"
                                                />
                                            </button>
                                        {:else if item.media_type === 'video'}
                                            <button
                                                type="button"
                                                class="h-full w-full cursor-pointer rounded-md object-contain"
                                                on:click="{() => openPreview(item.attachment.path, 'video')}"
                                            >
                                                <img
                                                    src="/svg/video.png"
                                                    alt="{item.name}"
                                                    loading="lazy"
                                                    class="h-full w-full rounded-md object-contain"
                                                />
                                            </button>
                                        {:else if item.type === 'folder'}
                                            <div
                                                role="button"
                                                tabindex="0"
                                                on:dragover|preventDefault="{() => {}}"
                                                on:drop="{(e) => onDrop(e, item)}"
                                            >
                                                <img
                                                    src="/svg/45480a3100a9edc6bf3b0ce03b3830e4-blue-folder-rounded.webp"
                                                    alt="Folder"
                                                    class="h-full w-full"
                                                />
                                            </div>
                                        {/if}
                                    </div>
                                </DropdownMenu.Trigger>

                                {#if item.type === 'folder' || item.media_type === 'image' || item.media_type === 'video'}
                                    <p class="m-0 p-0 text-sm font-medium">
                                        {truncateText(item.name, 10)}
                                    </p>

                                    {#if $activeDropdown === item.id}
                                        <DropdownMenu.Content>
                                            <DropdownMenu.Group>
                                                {#if item.type === 'folder'}
                                                    <DropdownMenu.Item
                                                        on:click="{$isMoveDialogOpen === false
                                                            ? () => openFolder(item)
                                                            : null}">Open</DropdownMenu.Item
                                                    >
                                                {/if}
                                                <DropdownMenu.Item on:click="{() => openRenameDialog(item)}"
                                                    >Edit</DropdownMenu.Item
                                                >
                                                <DropdownMenu.Item on:click="{() => openMoveDialog(item)}"
                                                    >Move</DropdownMenu.Item
                                                >
                                                <DropdownMenu.Item on:click="{() => openDeleteDialog(item)}"
                                                    >Delete</DropdownMenu.Item
                                                >
                                            </DropdownMenu.Group>
                                        </DropdownMenu.Content>
                                    {/if}
                                {/if}
                            </div>
                        </DropdownMenu.Root>
                    {/each}
                </div>
                {#if $isFetching || $subIsFetching}
                    <div class="mt-4 flex justify-center">
                        <Circle size="60" color="#6366F1" unit="px" duration="1s" />
                    </div>
                {/if}
            </div>
        {/if}

        {#if $currentFolderId}
            {#if $subItems.length === 0}
                <div class="flex h-[50vh] items-center justify-center text-lg text-gray-500">No data available</div>
            {/if}
            <div class="min-h-screen p-6">
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                    {#each $subItems as item}
                        <DropdownMenu.Root
                            open="{$activeDropdown === item.id}"
                            on:openChange="{(e) => (e.detail ? null : closeDropdown())}"
                        >
                            <div
                                role="button"
                                tabindex="0"
                                class="flex flex-col items-center space-y-2 rounded-lg p-4 text-center"
                                draggable="true"
                                on:dragstart="{(e) => onDragStart(e, item)}"
                                on:contextmenu="{(e) => openFolderContextMenu(e, item)}"
                            >
                                <DropdownMenu.Trigger>
                                    <div
                                        role="button"
                                        tabindex="0"
                                        class="flex h-[150px] w-[150px] cursor-pointer items-center justify-center rounded-lg"
                                        on:dblclick="{() =>
                                            item.type === 'folder' && $isMoveDialogOpen === false
                                                ? openFolder(item, 'move')
                                                : null}"
                                    >
                                        {#if item.media_type === 'image'}
                                            <img
                                                src="{item.attachment.thumbnail}"
                                                alt="{item.name}"
                                                class="h-full w-full rounded-md object-contain"
                                            />
                                        {:else if item.type === 'folder'}
                                            <div
                                                role="button"
                                                tabindex="0"
                                                on:dragover|preventDefault="{() => {}}"
                                                on:drop="{(e) => onDrop(e, item)}"
                                            >
                                                <img
                                                    src="/svg/45480a3100a9edc6bf3b0ce03b3830e4-blue-folder-rounded.webp"
                                                    alt="Folder"
                                                    class="h-full w-full"
                                                />
                                            </div>
                                        {/if}
                                    </div>
                                </DropdownMenu.Trigger>
                                <p class="truncate text-wrap text-sm font-medium">
                                    {truncateText(item.name || item.filename, 10)}
                                </p>
                                {#if $activeDropdown === item.id}
                                    <DropdownMenu.Content>
                                        <DropdownMenu.Group>
                                            {#if item.type === 'folder'}
                                                <DropdownMenu.Item
                                                    on:click="{!$isMoveDialogOpen ? () => openFolder(item) : null}"
                                                    >Open</DropdownMenu.Item
                                                >
                                            {/if}
                                            <DropdownMenu.Item on:click="{() => openRenameDialog(item)}"
                                                >Edit</DropdownMenu.Item
                                            >
                                            <DropdownMenu.Item on:click="{() => openMoveDialog(item)}"
                                                >Move</DropdownMenu.Item
                                            >
                                            <DropdownMenu.Item on:click="{() => openDeleteDialog(item)}"
                                                >Delete</DropdownMenu.Item
                                            >
                                        </DropdownMenu.Group>
                                    </DropdownMenu.Content>
                                {/if}
                            </div>
                        </DropdownMenu.Root>
                    {/each}
                </div>
            </div>
        {/if}
    {:else}
        <ListView items="{$currentFolderId ? $subItems : $rootItems}" {activeDropdown} />
    {/if}
    <!-- create folder dilog  -->
    <Dialog.Root open="{$isDialogOpen}" on:openChange="{(e) => isDialogOpen.set(e.detail)}">
        <Dialog.Content class="max-w-md rounded-lg  p-6 shadow-lg">
            <Dialog.Title class="text-lg font-semibold">Create Folder</Dialog.Title>
            <Dialog.Description class="mt-2 text-sm ">
                Enter the name of the folder you want to create.
            </Dialog.Description>
            <input
                type="text"
                bind:value="{$folderName}"
                placeholder="Folder Name"
                class="mt-4 w-full rounded-sm !bg-transparent px-3 py-2 text-sm"
            />
            <div class="mt-4 flex justify-end space-x-2">
                <Button
                    on:click="{() => isDialogOpen.set(false)}"
                    class="rounded-md px-4 py-2 text-sm"
                    variant="destructive">Cancel</Button
                >
                <Button on:click="{createFolder}" class="rounded-md px-4 py-2 text-white">Create</Button>
            </div>
        </Dialog.Content>
    </Dialog.Root>

    <!-- delete dilog  -->
    <Dialog.Root open="{$isDeleteDialogOpen}" on:openChange="{(e) => isDeleteDialogOpen.set(e.detail)}">
        <Dialog.Content class="max-w-md rounded-lg p-6 shadow-lg">
            <Dialog.Title class="text-lg font-semibold">Delete Item</Dialog.Title>
            <Dialog.Description class="mt-2 text-sm">
                Are you sure you want to delete? This action cannot be undone.
            </Dialog.Description>

            <div class="mt-4 flex justify-end space-x-2">
                <Button
                    on:click="{() => isDeleteDialogOpen.set(false)}"
                    class="rounded-md px-4 py-2 text-sm"
                    variant="destructive"
                >
                    Cancel
                </Button>
                <Button
                    on:click="{async () => {
                        await confirmDelete();
                    }}"
                    class="rounded-md px-4 py-2 text-sm text-white"
                >
                    Confirm
                </Button>
            </div>
        </Dialog.Content>
    </Dialog.Root>

    <!-- media preview dilog  -->
    <Dialog.Root bind:open="{$isMediaPreviewOpen}">
        <Dialog.Content class="rounded-lg p-6 shadow-lg">
            <Dialog.Title class="text-lg font-semibold">Media Preview</Dialog.Title>

            <div class="flex h-[50vh] items-center justify-center overflow-hidden">
                {#if $previewMediaType === 'image'}
                    <img src="{$previewMedia}" alt="Preview" class="h-full w-full rounded-lg object-cover" />
                {:else if $previewMediaType === 'video'}
                    <video controls autoplay class="h-full w-full rounded-md">
                        <source src="{$previewMedia}" type="video/mp4" />
                        <track src="/captions/sample.vtt" kind="captions" srclang="en" label="English" default />
                        Your browser does not support the video tag.
                    </video>
                {/if}
            </div>
        </Dialog.Content>
    </Dialog.Root>
</div>

<!-- Move Dialog -->
<Dialog.Root bind:open="{$isMoveDialogOpen}">
    <Dialog.Content class="rounded-lg p-6 shadow-lg">
        <Dialog.Title class="text-center text-lg font-semibold">Move Item {$itemToMove?.name}</Dialog.Title>

        {#if $moveDialogFolderId}
            <Button variant="outline" class="w-[15%] rounded-md py-2" on:click="{goBackMoveDialog}">Back</Button>
        {/if}

        <div class="mt-4 max-h-[60vh] overflow-y-auto">
            {#each $moveDialogFolderId ? $subItems : $rootItems as item}
                {#if item.type === 'folder'}
                    <div
                        class="flex cursor-pointer items-center justify-between rounded-md px-4 py-2"
                        style="background-color: {$selectedMoveFolder?.id === item.id ? '#64C4FF' : 'transparent'}"
                        on:click="{() => selectMoveFolder(item)}"
                        on:dblclick="{() => openMoveDialogFolder(item)}"
                        role="button"
                        tabindex="0"
                        on:keydown="{(event) => {
                            if (event.key === 'Enter') selectMoveFolder(item);
                            if (event.key === 'Enter' && event.detail === 2) openMoveDialogFolder(item);
                        }}"
                    >
                        <div class="flex items-center gap-2">
                            <img
                                src="/svg/45480a3100a9edc6bf3b0ce03b3830e4-blue-folder-rounded.webp"
                                class="h-6 w-6"
                                alt="folder"
                            />
                            <span>{item.name}</span>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>

        <div class="mt-4 flex justify-end space-x-2">
            <Button on:click="{() => isMoveDialogOpen.set(false)}" class="rounded-md px-4 py-2" variant="destructive"
                >Cancel</Button
            >
            <Button on:click="{moveItem}" class="rounded-md px-4 py-2">Move</Button>
        </div>
    </Dialog.Content>
</Dialog.Root>

<!-- edit folder  -->
<Dialog.Root bind:open="{$isRenameDialogOpen}">
    <Dialog.Content class="max-w-md rounded-lg p-6 shadow-lg">
        <Dialog.Title class="text-lg font-semibold">Rename Item</Dialog.Title>
        <Dialog.Description class="mt-2 text-sm">Enter a new name for your folder or file.</Dialog.Description>

        <input
            type="text"
            bind:value="{$newItemName}"
            placeholder="New Name"
            class="mt-4 w-full rounded-md border !bg-transparent px-3 py-2 text-sm"
        />

        <div class="mt-4 flex justify-end space-x-2">
            <Button
                on:click="{() => isRenameDialogOpen.set(false)}"
                class="rounded-md px-4 py-2 text-sm"
                variant="destructive">Cancel</Button
            >
            <Button on:click="{renameItem}" class="rounded-md px-4 py-2">Rename</Button>
        </div>
    </Dialog.Content>
</Dialog.Root>
